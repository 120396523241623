/* istanbul ignore file | configs */
import { GeneralModel } from '@cyferd/client-engine';
import { TRANS } from '@constants';
import { timezones } from '../GeneralInfo/timezones';

export const inputModel: any = {
  schema: {
    type: 'object',
    properties: {
      model: {
        type: 'object',
        title: TRANS.client.nav.builder.tabs.flows.inputForm,
        description: 'Set the schema for the data that can be passed as parameters when this flow is run.',
        format: GeneralModel.JSONSchemaFormat.COLLECTION_LITE,
        metadata: {
          detailOrder: 1,
          detailGroupId: 'input'
        }
      },
      prepare: {
        type: 'object',
        title: TRANS.client.nav.builder.tabs.flows.inputChanges,
        description: 'Use calculated values to further update or add input values for this flow.',
        format: GeneralModel.JSONSchemaFormat.EVALUATION, // TODO: should be KEY_VALUE
        metadata: {
          detailOrder: 2,
          fieldSize: GeneralModel.FieldSize.FULL,
          detailGroupId: 'input'
        }
      },
      state: {
        type: 'object',
        title: TRANS.client.nav.builder.tabs.flows.savedState,
        description: 'Manage the values that persist when the flow is saved, these values may be updated by the set state items in flow steps.',
        format: GeneralModel.JSONSchemaFormat.EVALUATION, // TODO: should be KEY_VALUE
        metadata: {
          detailOrder: 3,
          fieldSize: GeneralModel.FieldSize.FULL,
          detailGroupId: 'input'
        }
      }
    }
  },
  detailGroupList: [{ id: 'input', title: 'Input', icon: 'right_panel_open' }]
};

export const outputModel: any = {
  schema: {
    type: 'object',
    properties: {
      output: { type: 'any', title: 'Output', format: GeneralModel.JSONSchemaFormat.EVALUATION }
    }
  }
};

export const stepNotesModel: any = {
  schema: {
    type: 'object',
    properties: {
      notes: {
        type: 'string',
        title: TRANS.client.fields.titles.adminNotes,
        format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
        metadata: { fieldSize: GeneralModel.FieldSize.FULL }
      }
    }
  }
};

export const edgeModel: any = (optionList, hideParentTitle = false) => ({
  schema: {
    $id: 'edgeModel',
    type: 'object',
    title: hideParentTitle ? ' ' : TRANS.client.fields.titles.nextStepItem,
    required: ['goTo'],
    properties: {
      goTo: {
        type: 'string',
        title: TRANS.client.fields.titles.goTo,
        metadata: {
          optionList,
          detailOrder: 1
        }
      },
      if: {
        title: TRANS.client.fields.titles.condition,
        type: 'any',
        format: GeneralModel.JSONSchemaFormat.EVALUATION,
        metadata: {
          detailOrder: 2
        }
      },
      title: {
        type: 'string',
        title: TRANS.client.fields.titles.lineName,
        metadata: {
          detailOrder: 3
        }
      },
      description: {
        type: 'string',
        title: TRANS.client.fields.titles.description,
        format: GeneralModel.JSONSchemaFormat.MULTILINE,
        metadata: {
          detailOrder: 4
        }
      },
      color: {
        type: 'string',
        title: TRANS.client.fields.titles.color,
        format: GeneralModel.JSONSchemaFormat.COLOR,
        metadata: {
          detailOrder: 5
        }
      }
    }
  }
});

export const initialStepModel: any = (optionList, model) => ({
  schema: {
    type: 'object',
    properties: {
      schedules: {
        type: 'array',
        title: TRANS.client.fields.titles.schedules,
        format: GeneralModel.JSONSchemaFormat.ARRAY,
        metadata: { fieldSize: GeneralModel.FieldSize.FULL },
        items: {
          title: TRANS.client.fields.titles.schedule,
          type: 'object',
          properties: {
            enabled: {
              type: 'boolean',
              title: TRANS.client.fields.titles.enabled,
              format: GeneralModel.JSONSchemaFormat.CHECKBOX,
              default: true
            },
            dueAt: {
              type: 'string',
              title: TRANS.client.fields.titles.nextRun,
              format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
              metadata: {
                disabled: true
              }
            },
            payload: {
              type: 'object',
              title: TRANS.client.fields.titles.payload,
              format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
              properties: {},
              metadata: {
                collection: model,
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                fieldSize: GeneralModel.FieldSize.FULL
              }
            },
            cron: {
              type: 'object',
              title: TRANS.client.fields.titles.whenToRun,
              metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED, fieldSize: GeneralModel.FieldSize.FULL },
              properties: {
                type: {
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
                  metadata: {
                    optionList: [
                      { label: 'Once a day', value: 'daily' },
                      { label: 'Monday to Friday', value: 'weekdays' },
                      { label: 'Once a week', value: 'weekly' },
                      { label: 'Once a month', value: 'monthly' },
                      { label: 'Custom cron pattern', value: 'custom' }
                    ],
                    disabled: { $cyf_exists: ['{{event.parent.value.pattern}}'] },
                    detailOrder: 1
                  },
                  title: TRANS.client.fields.titles.type
                },
                pattern: {
                  type: 'string',
                  title: TRANS.client.fields.titles.customPattern,
                  format: GeneralModel.JSONSchemaFormat.TEXT,
                  description: 'Pattern format: "m h d M D"',
                  pattern:
                    '(@(annually|yearly|monthly|weekly|daily|hourly))|(@every (\\d+(ns|us|µs|ms|s|m|h))+)|((((\\d+,)+\\d+|(\\d+(\\/|-)\\d+)|\\d+|\\*) ?){5,7})',
                  metadata: {
                    hidden: { $cyf_and: [{ $cyf_not: [{ $cyf_exists: ['{{event.value}}'] }] }, { $cyf_ne: ['{{event.parent.value.type}}', 'custom'] }] }
                  }
                },
                dayOfMonth: {
                  type: 'number',
                  format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
                  metadata: {
                    optionList: Array.from(Array(31).keys()).map(index => ({ value: index + 1 })),
                    hidden: { $cyf_ne: ['{{event.parent.value.type}}', 'monthly'] }
                  },
                  title: TRANS.client.fields.titles.dayOfMonth
                },
                dayOfWeek: {
                  type: 'number',
                  title: TRANS.client.fields.titles.dayOWeek,
                  format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
                  metadata: {
                    optionList: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((label, index) => ({
                      label,
                      value: index + 1
                    })),
                    hidden: { $cyf_ne: ['{{event.parent.value.type}}', 'weekly'] }
                  }
                },
                time: {
                  title: TRANS.client.fields.titles.time,
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.TEXT,
                  pattern: '[0-9]{1,2}(:[0-9]{2})?',
                  description: 'Format: 00:00',
                  default: '09:00',
                  metadata: { hidden: { $cyf_not: [{ $cyf_in: ['{{event.parent.value.type}}', 'daily', 'weekdays', 'weekly', 'monthly'] }] } }
                },
                startDate: {
                  type: 'string',
                  title: TRANS.client.fields.titles.startDate,
                  format: GeneralModel.JSONSchemaFormat.DATE_TIME_U
                },
                endDate: {
                  type: 'string',
                  title: TRANS.client.fields.titles.endDate,
                  format: GeneralModel.JSONSchemaFormat.DATE_TIME_U
                },
                timezone: {
                  type: 'string',
                  title: TRANS.client.fields.titles.timezone,
                  format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
                  metadata: { optionList: timezones }
                }
              }
            }
          },
          metadata: { fieldSize: GeneralModel.FieldSize.FULL, startsCollapsed: true }
        }
      },
      onStart: {
        type: 'array',
        title: TRANS.client.nav.builder.tabs.flows.stepNext,
        metadata: { unlimitedHeight: true, fieldSize: GeneralModel.FieldSize.FULL },
        items: edgeModel(optionList).schema
      }
    }
  },
  detailGroupList: []
});

export const stepModel: any = optionList => ({
  schema: {
    type: 'object',
    properties: {
      id: {
        type: 'string',
        title: TRANS.client.fields.titles.id,
        description: 'WARNING: changing the ID may break your flow',
        metadata: { detailGroupId: 'info', detailOrder: 1 }
      },
      debug: {
        type: 'boolean',
        default: false,
        title: TRANS.client.fields.titles.debug,
        metadata: {
          detailGroupId: 'info',
          detailOrder: 2,
          hidden: {
            $cyf_sw: ['{{event.item.action}}', 'DISPATCH.']
          }
        }
      },
      'info-debug': {
        format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
        metadata: {
          fixedValue: '<i>Debug is not available for actions of type Dispatch</i>',
          unlimitedHeight: true,
          detailGroupId: 'info',
          detailOrder: 2,
          hidden: {
            $not: true,
            $cyf_sw: ['{{event.item.action}}', 'DISPATCH.']
          }
        }
      },
      name: { type: 'string', title: TRANS.client.fields.titles.stepName, metadata: { detailGroupId: 'info', detailOrder: 3 } },
      description: {
        type: 'string',
        title: TRANS.client.fields.titles.description,
        format: GeneralModel.JSONSchemaFormat.MULTILINE,
        metadata: { detailGroupId: 'info', detailOrder: 4 }
      },
      infoAction: {
        format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
        metadata: {
          detailGroupId: 'action',
          detailOrder: 5,
          fixedValue: '<h5>Action details</h5>',
          fieldSize: 'full',
          unlimitedHeight: true
        }
      },
      action: {
        type: 'string',
        title: TRANS.client.fields.titles.actionType,
        format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST,
        metadata: { detailOrder: 6, fieldSize: GeneralModel.FieldSize.FULL, detailGroupId: 'action' }
      },
      input: {
        title: 'Input',
        type: 'object',
        format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
        properties: {},
        metadata: {
          detailOrder: 7,
          detailGroupId: 'action',
          fieldSize: GeneralModel.FieldSize.FULL,
          subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
          allowFormula: true,
          isCollapsible: true,
          alt: false,
          collectionId: 'Flow',
          recordId: '{{event.item.action}}'
        }
      },
      onResult: {
        type: 'array',
        title: TRANS.client.nav.builder.tabs.flows.stepNext,
        metadata: {
          unlimitedHeight: true,
          fieldSize: GeneralModel.FieldSize.FULL,
          detailGroupId: 'routing',
          detailOrder: 10,
          collapsible: true,
          startsCollapsed: true
        },
        items: edgeModel(optionList).schema
      },
      onError: {
        type: 'array',
        title: TRANS.client.nav.builder.tabs.flows.stepError,
        metadata: {
          hidden: {
            $cyf_sw: ['{{event.item.action}}', 'DISPATCH.']
          },
          unlimitedHeight: true,
          fieldSize: GeneralModel.FieldSize.FULL,
          color: 'RD_2',
          detailGroupId: 'routing',
          detailOrder: 11,
          collapsible: true,
          startsCollapsed: true
        },
        items: edgeModel(optionList).schema
      },
      'info-onError': {
        format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
        metadata: {
          fixedValue: "<i>Dispatch actions don't have error routing</i>",
          unlimitedHeight: true,
          detailGroupId: 'routing',
          detailOrder: 11,
          hidden: {
            $not: true,
            $cyf_sw: ['{{event.item.action}}', 'DISPATCH.']
          }
        }
      },
      metadata: {
        type: 'object',
        title: ' ',
        properties: {
          color: { type: 'string', title: TRANS.client.fields.titles.color, format: GeneralModel.JSONSchemaFormat.COLOR },
          image: { type: 'string', title: TRANS.client.fields.titles.icon, format: GeneralModel.JSONSchemaFormat.ICON_IMAGE }
        },
        metadata: { detailGroupId: 'customize', detailOrder: 9 }
      },
      notes: {
        type: 'string',
        title: TRANS.client.fields.titles.adminNotes,
        format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
        metadata: { fieldSize: GeneralModel.FieldSize.FULL, detailGroupId: 'customize', detailOrder: 10 }
      },
      infoState: {
        format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
        metadata: {
          detailGroupId: 'state',
          detailOrder: 1,
          fixedValue: '<p>After a step runs successfully, you may make changes to the current state of the flow.</p>',
          fieldSize: 'full',
          unlimitedHeight: true
        }
      },
      setState: {
        type: 'any',
        title: TRANS.client.fields.titles.stateChanges,
        format: GeneralModel.JSONSchemaFormat.EVALUATION,
        metadata: { fieldSize: GeneralModel.FieldSize.FULL, detailGroupId: 'state', detailOrder: 2 }
      }
    }
  },
  detailGroupList: [
    {
      id: 'info',
      name: ' '
    },
    {
      id: 'action',
      name: 'Action',
      isCollapsible: true,
      startsCollapsed: true
    },
    {
      id: 'routing',
      name: 'Routing',
      isCollapsible: true,
      startsCollapsed: true
    },
    {
      id: 'customize',
      name: 'Customize',
      isCollapsible: true,
      startsCollapsed: true
    },
    {
      id: 'state',
      name: 'State',
      isCollapsible: true,
      startsCollapsed: true
    }
  ]
});
