import { COLOR, FONT_WEIGHT, GAP, RADIUS } from '@constants';
import type { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/css';

export const styles = {
  getCardContainer: (color: GeneralModel.Color.ThemeColor = 'BRAND_1', opacity: number) => css`
    min-height: 50px;
    padding: ${GAP.S};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.NEUTRAL_1};
    background-color: ${COLOR.BASE_BACKGROUND};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    display: flex;
    gap: ${GAP.XXS};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px ${COLOR[color] ?? COLOR.BRAND_1} solid;
    border-radius: ${RADIUS.M};
    width: 120px;
    height: 75px;
    opacity: ${opacity};
  `,
  initialStep: css`
    padding: 12.5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOR.NEUTRAL_1};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    display: flex;
    gap: ${GAP.XXS};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px ${COLOR.BRAND_1} solid;
    border-radius: ${RADIUS.M};
    border-radius: 100% !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: ${COLOR.BRAND_1};
    width: 50px;
    height: 50px;
  `,
  stepName: css`
    text-align: center;
  `,
  getIconContainer: (color: GeneralModel.Color.ThemeColor = 'BRAND_1', isInactive = false) => css`
    background-color: ${isInactive ? '' : (COLOR[color] ?? COLOR.BRAND_1)};
    border-radius: 100%;
    padding: ${GAP.XS};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  contextMenu: css`
    position: fixed;
    z-index: 10;
  `
};
