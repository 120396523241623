import L from 'leaflet';
import { useMemo } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Circle, Marker, Polygon, Tooltip } from 'react-leaflet';
import type { MapMarkerProps } from '../types';
import { MarkerType } from '../types';
import type { IconKeys, IconProps } from '@components/elements/Icon';
import { Icon } from '@components/elements/Icon';
import { COLOR } from '@constants';

const makeIcon = (icon = 'location_on', color = 'BRAND_1') => {
  const iconProps: IconProps = {
    name: icon as IconKeys,
    size: '40px',
    fill: COLOR[color],
    inlineStyle: true
  };

  return L.divIcon({
    html: ReactDOMServer.renderToString(<Icon {...iconProps} />),
    className: 'svg-icon',
    iconSize: [40, 40],
    iconAnchor: [20, 30]
  });
};

const defaultIcon = makeIcon();

export const MapMarker = ({ type, title, description, color, rawColor, icon, coords, radius, onClick }: MapMarkerProps) => {
  const children = (
    <Tooltip {...{ sticky: false }}>
      {title}
      <br />
      {description}
    </Tooltip>
  );

  const eventHandlers = {
    click: ({ latlng: coordinates, originalEvent: { metaKey } }) => onClick({ metaKey, coordinates })
  };

  const markerIcon = useMemo(() => {
    if (type !== MarkerType.POINT) return;
    if (!icon && !rawColor) return defaultIcon;
    return makeIcon(icon, rawColor);
  }, [icon, rawColor]);

  if (type === MarkerType.POLYGON) {
    return (
      <Polygon positions={coords as any} eventHandlers={eventHandlers} pathOptions={{ color, weight: 1 }}>
        {children}
      </Polygon>
    );
  }

  if (type === MarkerType.CIRCLE) {
    return (
      <Circle center={coords as any} pathOptions={{ color, fillColor: color }} eventHandlers={eventHandlers} {...{ radius }}>
        {children}
      </Circle>
    );
  }

  return (
    <Marker icon={markerIcon} position={coords as any} eventHandlers={eventHandlers}>
      {children}
    </Marker>
  );
};
