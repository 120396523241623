import { COLOR, FONT_WEIGHT, FOREGROUND_COLOR } from '@constants';
import type { CustomEdgeProps } from './types';
import { BaseEdge, EdgeLabelRenderer, EdgeText, getBezierPath } from '@xyflow/react';
import { ViewModel } from '@cyferd/client-engine';
import { CTA } from '@components/elements/CTA';
import { styles } from './styles';

export const CustomEdge = (props: CustomEdgeProps) => {
  const { selectedId, selected, data, actions, ...edgeProps } = props;
  const { source, target, id } = edgeProps;
  const { isError, routeItem, routeIndex, totalRoutes } = data;
  const isActive = [undefined, null, source, target, id].includes(selectedId);

  const edgeColor = COLOR[routeItem.color] || (isError ? COLOR.RD_4 : COLOR.BRAND_1);
  const stroke = isActive ? edgeColor : COLOR.NEUTRAL_3;

  const zIndex = isActive ? 100 : 50;

  const [path, labelX, labelY] = getBezierPath(edgeProps);
  const style = { stroke, strokeWidth: 2, strokeDasharray: isError ? 20 : routeItem.if ? 10 : 3, zIndex };

  const labelProps =
    totalRoutes.length > 1 || routeItem.title
      ? {
          label: routeItem.title || `${routeIndex + 1}`,
          labelBgStyle: { fill: COLOR[routeItem.color], opacity: isActive ? 1 : 0.3 },
          labelStyle: { fill: FOREGROUND_COLOR[routeItem.color], fontWeight: Number(FONT_WEIGHT.BOLD), opacity: isActive ? 1 : 0.3, zIndex: zIndex },
          labelBgPadding: [10, 5] as [number, number],
          labelBgBorderRadius: 4,
          x: labelX,
          y: labelY
        }
      : null;

  return (
    <>
      <BaseEdge {...edgeProps} path={path} style={style} />
      {labelProps && <EdgeText {...labelProps} />}
      {selected && (
        <EdgeLabelRenderer>
          <div className="nodrag nopan">
            <div className={styles.contextMenu} style={{ top: labelY + 5, left: labelX - 33, pointerEvents: 'all' }}>
              {actions
                ?.filter(({ shouldHide }) => !shouldHide?.(id, data))
                .map(({ color, icon, label, onClick }) => (
                  <CTA
                    key={label}
                    size={ViewModel.CTASize.SMALL}
                    type={ViewModel.CTAType.ACTION}
                    icon={icon}
                    tooltip={label}
                    color={color}
                    onClick={() => onClick(id)}
                  />
                ))}
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
