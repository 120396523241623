/* istanbul ignore file */
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Connection, Edge } from '@xyflow/react';
import { ReactFlow, Controls, MiniMap, isEdge, useNodesState, useEdgesState, Panel } from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import type { FlowModel } from '@cyferd/client-engine';
import { ErrorBoundary } from '@cyferd/client-engine';

import { getElements } from './getElements';
import { ContextMenu } from './components/ContextMenu';
import { SelfConnectingEdge } from './components/SelfConnectingEdge';
import { StartNode } from './components/StartNode';
import { CustomNode } from './components/CustomNode';
import { CustomEdge } from './components/CustomEdge';

export interface FlowChartProps {
  flow: FlowModel.Flow;
  activeStepId: string;
  initialStepId: string;
  onOpenMenu: (stepId: string) => void;
  onChange: (stepKey: string, step: FlowModel.FlowStep) => void;
  onStartAdd: (stepKey: string) => void;
  stepActions: any[];
  edgeActions: any[];
}

export const FlowChart = ({ flow, initialStepId, onOpenMenu, onChange, onStartAdd, stepActions, edgeActions }: FlowChartProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [menu, setMenu] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const elements = getElements(flow);

    setNodes(elements.nodes);
    setEdges(elements.edges);
  }, [flow, setNodes, setEdges]);

  const onConnect = useCallback(
    (connection: Edge | Connection) => {
      const { source, target } = connection;

      if (!flow || isEdge(connection) || target === initialStepId) return;

      if (source === initialStepId) onStartAdd(target);
      else onChange(source, { ...flow.steps[source], onResult: [...(flow.steps[source].onResult || []), { goTo: target }] } as FlowModel.FlowStep);
    },
    [initialStepId, onChange, onStartAdd, flow]
  );

  const onContextMenu = useCallback(
    (event, node?) => {
      // Prevent native context menu from showing
      event.preventDefault();

      onOpenMenu(null);

      if (!node) {
        setMenu({ event, actions: stepActions });
      }
    },
    [setMenu, onOpenMenu, edgeActions, stepActions]
  );

  const onSelectionChange = useCallback(
    selection => {
      setMenu(null);
      if (selection.nodes[0]) {
        setSelectedId(selection.nodes[0].id);
      } else if (selection.edges[0]) {
        setSelectedId(selection.edges[0].id);
      } else setSelectedId(null);
    },
    [setSelectedId]
  );

  // click anywhere
  const onPaneClick = useCallback(() => {
    setMenu(null);
    onOpenMenu(null);
  }, [onOpenMenu]);

  const nodeTypes = useMemo(() => {
    const customProps = {
      selectedId,
      actions: stepActions
    };
    return {
      startnode: props => <StartNode {...props} {...customProps} isToolbarVisible={props.selected} />,
      customnode: props => <CustomNode {...props} {...customProps} isToolbarVisible={props.selected} />
    };
  }, [selectedId, stepActions]);

  const edgeTypes = useMemo(() => {
    const customProps = {
      selectedId,
      actions: edgeActions
    };
    return {
      selfconnecting: props => <SelfConnectingEdge {...props} {...customProps} isToolbarVisible={props.selected} />,
      customedge: props => <CustomEdge {...props} {...customProps} isToolbarVisible={props.selected} />
    };
  }, [selectedId, edgeActions]);

  return (
    <ErrorBoundary>
      <ReactFlow
        onSelectionChange={onSelectionChange}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesConnectable={true}
        nodesDraggable={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        snapToGrid={true}
        snapGrid={[20, 20]}
        onPaneContextMenu={onContextMenu}
        onNodeContextMenu={onContextMenu}
        onEdgeContextMenu={onContextMenu}
        onPaneClick={onPaneClick}
      >
        <Panel position="bottom-left" style={{ margin: '50px' }}>
          <Controls showInteractive={false} />
        </Panel>
        <MiniMap pannable={true} zoomable={true} nodeColor={n => n.style.color} />
        {menu && <ContextMenu onClick={() => setMenu(null)} menu={menu} setMenu={setMenu} />}
      </ReactFlow>
    </ErrorBoundary>
  );
};
