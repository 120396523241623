import { omit } from 'lodash';
import { useCallback, useContext } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { createUUID, removeKeyList } from '@cyferd/client-engine';

import { getPrettyActionTitle } from '@utils';
import { initialStepId } from '../constants';
import { GlobalContext } from '../../../../../state-mgmt/GlobalState';
import { parseEdgeId } from '../components/FlowChart/getElements';

export const useFlowHandlers = ({ item, setItem, stepParentId, onToggleAddModal, setActiveStepKey, activeStepKey }) => {
  const { deps } = useContext(GlobalContext);

  const onStartAdd = useCallback(
    goTo =>
      setItem({
        ...item,
        onStart: [...item.onStart, { goTo }]
      }),
    [item, setItem]
  );

  const onAdd = useCallback(
    (action: FlowModel.FlowStep['action']) => {
      const stepId = createUUID();

      const newStep = { id: stepId, name: getPrettyActionTitle(action), action, debug: false, input: {}, onResult: [], onError: [] };

      if (stepParentId === initialStepId) {
        setItem({
          ...item,
          onStart: stepParentId === initialStepId ? [...item.onStart, { goTo: stepId }] : item.onStart,
          steps: {
            ...item.steps,
            [stepId]: newStep
          }
        });
      } else {
        setItem({
          ...item,
          steps: {
            ...item.steps,
            ...(stepParentId
              ? {
                  [stepParentId]: {
                    ...item.steps[stepParentId],
                    onResult: [...(item.steps[stepParentId].onResult || []), { goTo: stepId }]
                  }
                }
              : {}),
            [stepId]: newStep
          }
        });
      }

      onToggleAddModal();
      setActiveStepKey(stepId);
    },
    [item, stepParentId, onToggleAddModal, setItem, setActiveStepKey]
  );

  const onStepChange = useCallback(
    (key: string, step: FlowModel.FlowStep) => {
      if (!item.steps[key]) return;

      const newItem: FlowModel.Flow = { ...item, steps: { ...omit(item.steps, key), [step.id]: step } };
      if (key === step.id) {
        setItem(newItem);
      } else {
        const remapRouting = e => ({ ...e, goTo: e.goTo === key ? step.id : e.goTo });
        setItem({
          ...newItem,
          onStart: newItem.onStart.map(remapRouting),
          steps: Object.fromEntries(
            Object.entries(newItem.steps).map(([k, v]) => [
              k,
              {
                ...v,
                // we could try to manage id change in formulas, but it's impossible to cover all edge cases
                onResult: v.onResult?.map(remapRouting) || [],
                onError: v.onError?.map(remapRouting) || []
              }
            ])
          )
        });
      }
      setActiveStepKey(step.id);
    },
    [item, setItem, setActiveStepKey]
  );

  const onRemove = useCallback(
    async (key: string) => {
      const step: FlowModel.FlowStep = item.steps[key];

      if (!step) return;

      deps.modalInteraction.onConfirm(
        {
          title: `Delete ${step.name}`,
          description: 'Are you sure you want to delete this step?',
          icon: 'delete',
          confirmLabel: 'Delete'
        },
        () => {
          const filterFromResult = (r: FlowModel.FlowRouting) => r.goTo !== key;
          setItem({
            ...item,
            steps: Object.fromEntries(
              Object.entries(removeKeyList<FlowModel.Flow['steps']>(item.steps, [key])).map(([key, step]) => [
                key,
                {
                  ...step,
                  onResult: step?.onResult.filter(filterFromResult),
                  onError: step?.onError.filter(filterFromResult)
                }
              ])
            ),
            onStart: item.onStart.filter(filterFromResult)
          });
          if (key === activeStepKey) setActiveStepKey(null);
        }
      );
    },
    [item, setItem, activeStepKey, setActiveStepKey]
  );

  const onClone = useCallback(
    key => {
      const stepId = createUUID();
      const node = item.steps[key];
      setItem({
        ...item,
        steps: {
          ...item.steps,
          [stepId]: {
            ...node,
            id: stepId,
            name: `${node.name} copy`,
            onResult: [],
            onError: []
          }
        }
      });
      setActiveStepKey(null);
    },
    [item, setItem, setActiveStepKey]
  );

  const onToggleDebug = useCallback(
    stepId => {
      setItem({
        ...item,
        steps: {
          ...item.steps,
          [stepId]: {
            ...item.steps[stepId],
            debug: !item.steps[stepId].debug
          }
        }
      });
    },
    [item, setItem]
  );

  const onClearAllDebug = useCallback(() => {
    setItem({
      ...item,
      steps: Object.fromEntries(Object.entries(item.steps).map(([k, v]: any) => [k, { ...v, debug: false }]))
    });
  }, [item, setItem]);

  const onSetNotes = useCallback(
    (_stepId, _notes) => {
      // toggle notes modal
      // setItem({
      //   ...item,
      //   steps: {
      //     ...item.steps,
      //     [stepId]: {
      //       ...item.steps[stepId],
      //       notes,
      //     }
      //   }
      // })
    },
    [
      /*item, setItem*/
    ]
  );

  const onEdgeChange = useCallback(
    (key, value) => {
      const { source, routeType, routeIndex } = parseEdgeId(key);

      setItem({
        ...item,
        steps: {
          ...item.steps,
          [source]: {
            ...item.steps[source],
            [routeType]: (item.steps[source][routeType] as Array<any>).toSpliced(routeIndex, 1, value)
          }
        }
      });
    },
    [item, setItem]
  );

  const onEdgeTypeToggle = useCallback(
    key => {
      const { source, routeType, routeIndex } = parseEdgeId(key);
      const isError = routeType === 'onError';

      setItem({
        ...item,
        steps: {
          ...item.steps,
          [source]: {
            ...item.steps[source],
            onResult: isError
              ? [...(item.steps[source].onResult || []), item.steps[source].onError[routeIndex]]
              : item.steps[source].onResult?.toSpliced(routeIndex, 1) || [],
            onError: isError
              ? item.steps[source].onError?.toSpliced(routeIndex, 1) || []
              : [...(item.steps[source].onError || []), item.steps[source].onResult[routeIndex]]
          }
        }
      });
    },
    [item, setItem]
  );

  const onEdgeRemove = useCallback(
    key => {
      const { source, routeType, routeIndex } = parseEdgeId(key);
      deps.modalInteraction.onConfirm(
        {
          title: 'Disconnect',
          description: 'These steps will be disconnected',
          icon: 'delete',
          confirmLabel: 'Disconnect'
        },
        () => {
          setActiveStepKey(null);

          if (source === initialStepId) {
            setItem({
              ...item,
              onStart: item.onStart.toSpliced(routeIndex, 1)
            });
          } else {
            setItem({
              ...item,
              steps: {
                ...item.steps,
                [source]: {
                  ...item.steps[source],
                  [routeType]: item.steps[source][routeType].toSpliced(routeIndex, 1)
                }
              }
            });
          }
        }
      );
    },
    [item, setItem, setActiveStepKey]
  );

  return {
    onAdd,
    onClearAllDebug,
    onClone,
    onRemove,
    onSetNotes,
    onStartAdd,
    onStepChange,
    onToggleDebug,
    onEdgeChange,
    onEdgeTypeToggle,
    onEdgeRemove
  };
};
