import { ViewModel, type FlowModel } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';

import { styles } from './styles';
import { Handle, NodeToolbar, Position } from '@xyflow/react';
import { CTA } from '@components/elements/CTA';
import type { CustomNodeProps } from './types';

export const StartNode = ({ id, data, actions, isToolbarVisible }: CustomNodeProps) => {
  const { flow } = data;
  return (
    <>
      <NodeToolbar position={Position.Bottom} isVisible={isToolbarVisible}>
        {actions
          ?.filter(({ shouldHide }) => !shouldHide?.(id, data))
          .map(({ color, icon, label, onClick }) => (
            <CTA
              key={label}
              size={ViewModel.CTASize.SMALL}
              type={ViewModel.CTAType.ACTION}
              icon={icon}
              tooltip={label}
              color={color}
              onClick={() => {
                onClick(id, data);
              }}
            />
          ))}
      </NodeToolbar>
      <ToolTip text={<TooltipInfo title="Start" />}>
        <div className={styles.initialStep}>
          <div className={styles.getIconContainer('BRAND_1')}>
            <IconImage
              title=""
              icon="play_circle"
              imageProps={{
                css: { maxWidth: FONT_SIZE.XXXL, height: FONT_SIZE.XXXL, objectFit: 'cover', borderRadius: '100%', backgroundColor: COLOR.BASE_BACKGROUND }
              }}
              iconProps={{ size: FONT_SIZE.XL, fill: FOREGROUND_COLOR['BRAND_1'] }}
            />
          </div>
        </div>
      </ToolTip>
      <Handle type="source" position={Position.Right} id={id} isConnectable={true} />
      <div style={{ display: 'flex' }}>
        {!!(flow as FlowModel.Flow).schedules?.length && (
          <div className={styles.getIconContainer('BASE_BACKGROUND')}>
            <IconImage title="Scheduled" icon="schedule" />
          </div>
        )}
      </div>
    </>
  );
};
