/* istanbul ignore file */

import { Position, type Edge, type Node } from '@xyflow/react';

import type { FlowModel } from '@cyferd/client-engine';
import { COLOR, RADIUS } from '@constants';
import { initialStepId } from '../../constants';

// x for the first node from initial step
const START_X = 120;
// we leave room for unconnected steps
const START_Y = 100;
const SEPARATION_X = 220;
const SEPARATION_Y = 100;
const NODE_HEIGHT = 75;
const NODE_WIDTH = 120;

export const parseEdgeId = str => {
  if (!str.includes('|')) return;
  const [source, routeType, routeIndex, target] = str.split('|');
  return {
    source,
    target,
    routeIndex,
    routeType
  };
};

const makeEdge =
  (step: FlowModel.FlowStep, isError = false) =>
  (routeItem: FlowModel.FlowRouting, routeIndex: number, totalRoutes): Edge => {
    const id = [step.id, isError ? 'onError' : 'onResult', routeIndex, routeItem.goTo].join('|');

    return {
      id,
      source: step.id,
      target: routeItem.goTo,
      type: step.id === routeItem.goTo ? 'selfconnecting' : 'customedge',
      animated: !isError,
      data: {
        parent: step,
        targetId: routeItem.goTo,
        parentAction: step.action,
        isError,
        routeIndex,
        routeItem,
        totalRoutes
      }
    };
  };

interface MakeNodeProps {
  step: FlowModel.FlowStep;
  position: { x: number; y: number };
  parentId?: string;
}

const makeNode = ({ step, position, parentId }: MakeNodeProps): Node => ({
  id: step.id,
  type: 'customnode',
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  data: {
    parents: parentId ? [parentId] : [],
    step,
    id: step.id,
    action: step.action,
    name: step.name,
    debug: step.debug,
    notes: step.notes
  },
  selectable: true,
  style: { color: COLOR[step.metadata?.color || 'BRAND_1'], margin: 0, padding: 0, border: 0, borderRadius: RADIUS.M, width: NODE_WIDTH, height: NODE_HEIGHT },
  position,
  width: NODE_WIDTH,
  height: NODE_HEIGHT
});

const makeStartNode: (flow: FlowModel.Flow) => Node = flow => ({
  id: initialStepId,
  type: 'startnode',
  sourcePosition: Position.Right,
  data: {
    flow,
    id: initialStepId,
    name: 'Initial step'
  },
  connectable: true,
  selectable: true,
  style: { margin: 0, padding: 0, border: 0, borderRadius: '100%', width: 50, height: 50 },
  position: { x: 0, y: START_Y + 12.5 }, // compensate for size difference
  width: 50,
  height: 50
});

export const getElements = (flow: FlowModel.Flow) => {
  const nodeMap: { [k: string]: Node } = {};
  const nodePositions = new Set();
  const edges = [];

  const processRoute =
    (step, position, isError = false) =>
    (route, index, total) => {
      if (!flow?.steps?.[route.goTo]) return;

      if (nodePositions.has(position.x + '|' + position.y)) {
        return processRoute(step, { x: position.x, y: position.y + SEPARATION_Y }, isError)(route, index, total);
      }
      nodePositions.add(position.x + '|' + position.y);
      // eslint-disable-next-line no-restricted-syntax
      edges.push(makeEdge(step, isError)(route, index, total));
      followTheRoute(step.id, route.goTo, flow.steps[route.goTo], position);
    };

  const followTheRoute = (parentId, key, step, position) => {
    if (nodeMap[key]) {
      // eslint-disable-next-line no-restricted-syntax
      (nodeMap[key].data.parents as any).push(parentId);
      return;
    }

    nodeMap[key] = makeNode({ parentId, step, position });

    const x = position.x + SEPARATION_X;
    // we are going to increase the value of y for each new node we connect
    let y = position.y - SEPARATION_Y;

    step.onResult?.forEach((route, index, total) => processRoute(step, { x, y: step.id === route.goTo ? y : (y += SEPARATION_Y) })(route, index, total));
    step.onError?.forEach((route, index, total) => processRoute(step, { x, y: step.id === route.goTo ? y : (y += SEPARATION_Y) }, true)(route, index, total));
  };

  flow?.onStart?.forEach((route, index, total) => processRoute({ id: initialStepId }, { x: START_X, y: START_Y + SEPARATION_Y * index })(route, index, total));

  const nodes = [makeStartNode(flow), ...Object.values(nodeMap)];

  Object.entries(flow?.steps || {})
    .filter(([key]) => !nodeMap[key])
    .sort(([, a], [, b]) => a.name.localeCompare(b.name))
    .forEach(([, step]: [string, FlowModel.FlowStep], i) => {
      // eslint-disable-next-line no-restricted-syntax
      nodes.push(makeNode({ step, position: { x: i * 150, y: -50 } }));
      // eslint-disable-next-line no-restricted-syntax
      // edges.push(...(step.onResult?.map(makeEdge(step)) || []), ...(step.onError?.map(makeEdge(step, true)) || []));
    });

  return { nodes, edges };
};
