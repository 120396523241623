import { takeUntil, tap } from 'rxjs';
import { ApiModel, ViewModel, ofType, swallowError, useUnmountObservable } from '@cyferd/client-engine';
import { useCallback, useContext, useState } from 'react';
import { CyWrapperContext } from '../../../smart/CyWrapper';
import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { TRANS } from '@constants';

export const useShortLink = () => {
  const [createdShortlink, setCreatedShortlink] = useState('');
  const { useAction } = useContext(CyWrapperContext);
  const onDestroy$ = useUnmountObservable();
  const onShortLink = useAction('coreShortLink');
  const onNavigate = useAction('dispatchNavigateTo');

  const onClick = () => {
    onShortLink({ target: { path: '{{url.pathname}}', qs: '{{url.queryParams}}' as any } })
      .pipe(
        takeUntil(onDestroy$),
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        tap(response => {
          const label = document.title || 'Link to Cyferd';
          const text = new ClipboardItem({
            'text/plain': new Blob([response.url], { type: 'text/plain' }),
            'text/html': new Blob([`<a href="${response.url}">${label}</a>`], { type: 'text/html' })
          });

          navigator.clipboard.write([text]);
          setCreatedShortlink(response.url);
        }),
        swallowError()
      )
      .subscribe();
  };

  const onClose = () => setCreatedShortlink('');
  const onGoToShortlink = useCallback(() => onNavigate({ path: createdShortlink, external: true }), [createdShortlink, onNavigate]);

  const ShortLinkModal = useCallback(
    () => (
      <Modal
        type={ViewModel.ModalType.COMPACT}
        description="Link copied to clipboard"
        footer={<OptionMenu optionList={[{ important: true, testid: 'close', type: CTAType.PRIMARY, label: 'OK', onClick: onClose }]} />}
        open={!!createdShortlink}
        onClose={onClose}
        title="Success"
        testid="shortlink-modal"
      >
        <div>
          <CTA label={createdShortlink} type={CTAType.LINK} onClick={onGoToShortlink} />
        </div>
      </Modal>
    ),
    [createdShortlink, onGoToShortlink]
  );

  return {
    onClick,
    ShortLinkModal
  };
};

export const Shortlink = () => {
  const { onClick, ShortLinkModal } = useShortLink();
  return (
    <>
      <CTA
        testid="get-short-link"
        icon="share"
        outlined={true}
        type={CTAType.LINK}
        size={ViewModel.CTASize.LARGE}
        tooltip={TRANS.client.nav.share}
        color="NEUTRAL_1"
        onClick={onClick}
      />
      <ShortLinkModal />
    </>
  );
};
