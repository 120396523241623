import type { FlowModel } from '@cyferd/client-engine';
import { getClassnames, ViewModel } from '@cyferd/client-engine';
import { Handle, NodeToolbar, Position } from '@xyflow/react';

import { COLOR, FONT_SIZE, FOREGROUND_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { PopoverTrigger } from '@components/elements/Popover';
import { ToolTip } from '@components/elements/Tooltip';
import { TooltipInfo } from '@components/elements/TooltipInfo';

import { styles } from './styles';
import { CTA } from '@components/elements/CTA';
import type { CustomNodeProps } from './types';
import { parseEdgeId } from '../getElements';

const getOpacity = ({
  selectedId,
  selected,
  step,
  parents
}: {
  selected: boolean;
  selectedId: string;
  parents: string[];
  step: FlowModel.FlowStep;
}): number => {
  if (!selectedId) return 1;
  if (selected) return 1;
  const edge = parseEdgeId(selectedId);

  if (edge && [edge.source, edge.target].includes(step.id)) return 1;
  if (parents.some(parentId => parentId === selectedId)) return 0.5;
  if (step.onResult?.some(({ goTo }) => goTo === selectedId)) return 0.5;
  if (step.onError?.some(({ goTo }) => goTo === selectedId)) return 0.5;
  return 0.1;
};

export const CustomNode = (props: CustomNodeProps) => {
  const { selected, data, id, selectedId, isToolbarVisible, actions } = props;
  const { parents, step } = data;
  const isInactive = selectedId && !selected;

  const opacity = getOpacity({ selected, selectedId, parents, step });

  return (
    <>
      <NodeToolbar position={Position.Bottom} isVisible={isToolbarVisible}>
        {actions
          ?.filter(({ shouldHide }) => !shouldHide?.(id, data))
          .map(({ color, icon, label, onClick }) => (
            <CTA
              key={label}
              size={ViewModel.CTASize.SMALL}
              type={ViewModel.CTAType.ACTION}
              icon={icon}
              tooltip={label}
              color={color}
              onClick={() => {
                onClick(id, data);
              }}
            />
          ))}
      </NodeToolbar>
      <ToolTip text={<TooltipInfo title={step.name} description={[step.action, step.description].filter(Boolean).join('. ')} />}>
        <div className={getClassnames(styles.getCardContainer(step.metadata?.color, opacity))}>
          {!!step.metadata?.image && (
            <div className={styles.getIconContainer(step.metadata?.color)}>
              <IconImage
                title=""
                icon={step.metadata?.image as any}
                imageProps={{
                  css: { maxWidth: FONT_SIZE.XXXL, height: FONT_SIZE.XXXL, objectFit: 'cover', borderRadius: '100%', backgroundColor: COLOR.BASE_BACKGROUND }
                }}
                iconProps={{ size: FONT_SIZE.M, fill: FOREGROUND_COLOR[step.metadata?.color || 'BRAND_1'] }}
              />
            </div>
          )}
          {!!step.name && (
            <span className={styles.stepName}>
              {step.name.substring(0, 25)}
              {step.name.length > 25 && '...'}
            </span>
          )}
        </div>
      </ToolTip>

      <Handle type="target" position={Position.Left} id={id} isConnectable={true} />
      {!!parents.length && <Handle type="source" position={Position.Right} id={id} isConnectable={true} />}

      <div style={{ display: 'flex' }}>
        {step.debug && (
          <div className={styles.getIconContainer('OE_2', isInactive)}>
            <IconImage title="Debug" icon="pest_control" iconProps={{ css: { opacity } }} />
          </div>
        )}
        {step.notes && (
          <PopoverTrigger value={step.notes}>
            <div className={styles.getIconContainer('YW_1', isInactive)}>
              <IconImage title="Notes" icon="note" iconProps={{ css: { opacity } }} />
            </div>
          </PopoverTrigger>
        )}
      </div>
    </>
  );
};
